import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { colors } from '../styles/theme';
import SEO from '../components/SEO';

const SponsorPaymentPage = ({ location }) => {
  const [isSuccessful, setSuccessful] = useState(null);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      setSuccessful(params.get('result') === 'success');
    }
  }, []);

  return (
    <>
      <SEO title={isSuccessful ? 'Payment Succeed' : 'Payment Canceled'} />
      <Layout>
        {isSuccessful === true ? (
          <Container>
            <h1>Thank you for reserving your sponsorship with us.</h1>
            <p>
              A Neu team member will be in touch soon.
            </p>
          </Container>
        ) : isSuccessful === false ? (
          <Container>
            <h1>Payment was canceled.</h1>
            <p>Your card has not been charged.</p>
          </Container>
        ) : (
          <Container></Container>
        )}
      </Layout>
    </>
  );
};
const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 3rem;
  row-gap: 1.5rem;

  a {
    color: ${colors.blue};
  }
`;

export default SponsorPaymentPage;
